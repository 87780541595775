export const I18N_NAMESPACES = [
  'auth',
  'bases',
  'business',
  'common',
  'contractors',
  'dashboard',
  'delegations',
  'dictionaries',
  'documents',
  'drivers',
  'employees',
  'engine-config',
  'fleet',
  'import',
  'integrations',
  'map',
  'nav-submenu',
  'orders',
  'points-of-interest',
  'route',
  'settings',
] as const;
